import React, { useEffect, useState } from 'react';
import CloseIcon from '../../assets/svg/close.svg';

const NAVBAR_MENU_VISIBLE_CLASS = 'navbar-menu-is-visible';
const ACTIVE_CLASS = 'is-active';

const NavbarMenuToggle = () => {
    const [navbarMenuIsVisible, setNavbarMenuIsVisible] = useState();
    const headerElement = document.querySelector('.header');
    const menuElement = document.querySelector('.navbar-menu');

    useEffect(() => {
        if(navbarMenuIsVisible) {
            headerElement.classList.add(NAVBAR_MENU_VISIBLE_CLASS);
            menuElement.classList.add(ACTIVE_CLASS);
        } else {
            headerElement.classList.remove(NAVBAR_MENU_VISIBLE_CLASS);
            menuElement.classList.remove(ACTIVE_CLASS);
        }
    }, [navbarMenuIsVisible]);

    return (
        <a role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarMenu" onClick={() => setNavbarMenuIsVisible(!navbarMenuIsVisible)}>
          {!navbarMenuIsVisible && (
            <>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </>
          )}
          {navbarMenuIsVisible && (
              <CloseIcon className="navbar-close" />
          )}
        </a>
    );
};

export default NavbarMenuToggle;
